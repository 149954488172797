import { useQuery } from "@tanstack/vue-query";

export const useSearchOffersQuery = () => {
  const store = useCommandPaletteStore();

  const queryKey = [
    `command-palette-offer-search`,
    computed(() => store.input),
  ];
  return useQuery({
    queryKey,
    queryFn: async () =>
      await $apiFetch<any>(`/api/offers?superinput[]=${store.input}`),
  });
};
