import { useQuery } from "@tanstack/vue-query";

export const useAutocompleteQuery = () => {
  const store = useCommandPaletteStore();

  const queryKey = [`command-palette-search`, computed(() => store.input)];
  return useQuery({
    queryKey,
    queryFn: async () =>
      await $apiFetch<any>(
        `/api/homepage-data/autocomplete?searchString=${store.input}`,
      ),
  });
};
