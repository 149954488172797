<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Vrstva_1" clip-path="url(#clip0_22596_51571)">
      <path
        id="Vector"
        d="M12.6663 5.33301H1.33301V12.6663H12.6663V5.33301Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        id="Vector_2"
        d="M9.66634 1.33301H4.33301V5.33301H9.66634V1.33301Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_22596_51571">
        <rect
          width="13.3333"
          height="13.3333"
          fill="white"
          transform="translate(0.333008 0.333008)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
