<template>
  <div v-html="formattedString" />
</template>
<script setup lang="ts">
const props = defineProps<{
  inputString: string;
  searchPhrase: string;
}>();

const formattedString = computed(() => {
  // Escape special characters in the search phrase
  const escapedSearchPhrase = escapeRegExp(props.searchPhrase);

  // Split the string and wrap the search phrase in <span> tags
  const resultArray = props.inputString.split(
    new RegExp(`(${escapedSearchPhrase})`, "i"),
  );

  // Filter out empty strings
  const filteredArray = resultArray.filter((part) => part !== "");

  // Wrap the search phrase in <span> tags
  const formattedArray = filteredArray.map((part) => {
    return part.toLowerCase() === props.searchPhrase.toLowerCase()
      ? `<span class="bg-blue-700">${part}</span>`
      : part;
  });

  // Join the array to create the final HTML string
  return formattedArray.join("");
});
const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};
</script>
