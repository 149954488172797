export const useCommandPaletteStore = defineStore(
  "command-palette-search",
  () => {
    const input = ref("");

    const lastSearched: Ref<string[]> = ref([]);

    const saveLastSearch = (term: string) => {
      if (term.trim() === "") {
        return;
      }

      const index = lastSearched.value.findIndex(
        (searched) => searched === term,
      );

      if (index !== -1) {
        lastSearched.value.splice(index, 1);
      }

      lastSearched.value.unshift(term);

      while (lastSearched.value.length > 5) {
        lastSearched.value.pop();
      }
    };

    const removeLastSearched = (term: string) => {
      lastSearched.value = lastSearched.value.filter(
        (searched) => searched !== term,
      );
    };

    const reset = () => {
      input.value = "";
    };

    watchDebounced(input, saveLastSearch, { debounce: 2000 });

    return {
      input,
      lastSearched,
      saveLastSearch,
      removeLastSearched,
      reset,
    };
  },
);
